import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as Suspense from './suspense'
import * as Urql from 'urql'

import {Helmet} from 'react-helmet-async'
import PropTypes from 'prop-types'

const getSeoMetadata = `
  query SeoMetadata($url: String!) {
    seoMetadata(url: $url) {
      description
      title
    }
  }
`

function SeoMetadata(props) {
  const translate = Herz.I18n.useTranslate(
    () => [props.description, props.title],
    [props.description, props.title]
  )

  return (
    <Helmet>
      <title>{translate(props.title)}</title>
      <meta name="title" content={translate(props.title)} />
      {translate(props.description) && (
        <meta name="description" content={translate(props.description)} />
      )}
    </Helmet>
  )
}

SeoMetadata.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}

export function SeoMetadataContainer() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouterDom.useLocation()

  const [response] = Urql.useQuery({
    query: getSeoMetadata,
    variables: {url: location.pathname + location.search},
  })

  if (
    response.fetching ||
    response.error ||
    R.path(['seoMetadata'], response.data) == null
  ) {
    return (
      <Helmet>
        <title>{translate('seo.start.title')}</title>
        <meta name="title" content={translate('seo.start.title')} />
        <meta name="description" content={translate('seo.start.desc')} />
      </Helmet>
    )
  }

  return (
    <Suspense.Boundary fallback={null}>
      <SeoMetadata
        description={R.path(['seoMetadata', 'description'], response.data)}
        title={R.path(['seoMetadata', 'title'], response.data)}
      />
    </Suspense.Boundary>
  )
}

Herz.I18n.Loader.preload(
  ['seo.start.title', 'seo.start.desc'],
  SeoMetadataContainer
)

export default SeoMetadataContainer
